import classNames from "classnames";
import Phone from "../assets/phone.svg";
import Calendar from "../assets/calendar.svg";
import Location from "../assets/location.svg";
import Favourites from "../assets/favourites.png";
import Reboot from "../assets/reboot.png";
import { useEffect, useRef, useState } from "react";
import { getPartner } from "../helper/Http";
import { toast } from "sonner";

const PartnerModal = ({ id, setOrderModalView }) => {
  const modalRef = useRef(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // axios(`https://api-dev.coffeein.az/user-ws/v1/site/partners/${id}`).then((res) => {
    //   if (res?.data?.message === "SUCCESS") {
    //     setData(res?.data?.data);
    //   }
    // })
    // .catch((err) => {
    //   toast.error("Xəta baş verdi");
    // })
    // .finally(() => {
    //   setLoading(false);
    // });
    getPartner(id)
      .then((res) => {
        if (res?.data?.message === "SUCCESS") {
          setData(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error("Xəta baş verdi");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    function handleClickOutside(event) {
      const menuRefCurrent = modalRef.current;
      if (menuRefCurrent && !menuRefCurrent.contains(event.target)) {
        setOrderModalView(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id]);
  return (
    <div className="fixed left-0 top-0 bg-black/50 z-50 h-screen w-full flex justify-center items-center">
      {!loading && (
        <div
          ref={modalRef}
          className="bg-[#FCFDFF] rounded-2xl w-[640px]  relative"
        >
          <div className="w-full  mt-12">
            <div className="relative w-full flex justify-center items-center">
              <div className="flex w-[300px] justify-center items-center relative">
                {data?.galleries?.map((el, i) => {
                  const totalElements = data?.galleries?.length || 0;
                  const offset = (totalElements - 1) * 50;
                  const isLast = i === totalElements - 1;
                  return (
                    <img
                      key={i}
                      className={`min-w-[72px] min-h-[72px] max-w-[72px] max-h-[72px] object-cover rounded-full absolute ${
                        isLast
                          ? "!min-w-14 !min-h-14 !max-w-14 !max-h-14 border-none"
                          : "border-[8px] border-white"
                      }`}
                      style={{
                        left:
                          totalElements > 1
                            ? `calc(50% - ${offset / 2}px + ${i * 40}px)`
                            : "39%",
                        zIndex: totalElements - i,
                      }}
                      src={el?.icon}
                      alt="galleries"
                    />
                  );
                })}
                {data?.galleries?.length > 0 && (
                  <div
                    className="flex justify-center items-center px-3 py-1 gap-2 linear-color rounded-3xl absolute"
                    style={{
                      left: `calc(50% - ${
                        (data?.galleries?.length - 1) * 20
                      }px + ${(data?.galleries?.length - 1) * 42}px)`,
                      top: 0,
                      zIndex: 10000,
                    }}
                  >
                    <p className="text-white text-lg">{data?.cashback}%</p>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.88981 0.0346585C6.51679 0.0404341 6.13612 0.074657 5.75157 0.141982C2.94255 0.634902 0.674296 2.87553 0.155439 5.68112C-0.6613 10.0983 2.72593 13.9667 6.99992 13.9667C10.5778 13.9667 13.5335 11.2561 13.923 7.7809C13.9876 7.20666 13.5381 6.69999 12.9583 6.69999C12.899 6.69999 12.8406 6.7054 12.7837 6.71581C12.6989 6.73132 12.6173 6.75795 12.5409 6.79432C12.2542 6.9308 12.0407 7.20462 12.0038 7.54441C11.7314 10.0639 9.5902 12.0333 6.99992 12.0333C3.89115 12.0333 1.43167 9.20087 2.06768 5.97865C2.44967 4.04294 4.00068 2.47655 5.93297 2.07765C6.56675 1.94684 7.18453 1.93653 7.76876 2.02771C8.74414 2.17993 9.62599 2.61499 10.3326 3.24433L9.72267 3.85427L9.59768 3.97926C9.43733 4.13962 9.36702 4.33736 9.36941 4.53055C9.37427 4.92475 9.68178 5.29999 10.1445 5.29999H10.4474H11.2692H12.9999C13.5336 5.29999 13.9666 4.86701 13.9666 4.33332V1.47785C13.9666 0.789319 13.1336 0.440929 12.6456 0.930019L11.7001 1.87557C10.437 0.712799 8.74682 0.00590501 6.88981 0.0346585Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
          <h3 className="text-center mt-10 text-2xl font-semibold">
            {data?.name}
          </h3>
          <div className="mt-8 ">
            <div className="flex gap-3  overflow-x-scroll px-10  snap-x mt-8 md:mt-10 hide-scroll">
              {data &&
                data?.amenities?.map((el) => {
                  return (
                    <div
                      key={el?.id}
                      className="bg-[#F7F7F7] flex justify-center items-center rounded-[99px] gap-1 p-3"
                    >
                      <div className="w-[18px] h-[18px]">
                        <img
                          className="w-full h-full object-cover"
                          src={el?.icon}
                          alt={el?.name}
                        />
                      </div>
                      <button
                        className={classNames(
                          " text-[#000] text-sm   whitespace-nowrap"
                        )}
                      >
                        {el?.name}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="space-y-2  mt-4 px-10">
            <div className="flex items-center gap-4">
              <div className="w-7 h-7">
                {" "}
                <img src={Calendar} alt="calendar" />
              </div>
              <div className="space-y-1">
                <p className="text-sm text-[#888888]">İş saatları</p>
                <p className="text-base text-black font-medium">
                  <span>{data?.openTime}</span>-<span>{data?.closeTime}</span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="w-7 h-7">
                {" "}
                <img src={Phone} alt="phone" />
              </div>
              <div className="space-y-1">
                <p className="text-sm text-[#888888]">Əlaqə nömrəsi</p>
                <a
                  href={`tel:${data?.mobile}`}
                  className="text-base text-[#007AFF] font-medium"
                >
                  {data?.mobile}
                </a>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="w-7 h-7">
                {" "}
                <img src={Location} alt="phone" />
              </div>
              <div className="space-y-1">
                <p className="text-sm text-[#888888]">Ünvan</p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://www.google.com/maps?q=${data?.lat},${data?.lng}`}
                  className="text-base text-[#007AFF] font-medium"
                >
                  {data?.address}
                </a>
              </div>
            </div>
          </div>
          <div className=" flex gap-4 mt-8 px-10 pb-10">
            <div className="bg-[#F7F7F7] p-6 rounded-2xl w-full">
              <div className="w-12 h-12">
                <img src={Favourites} alt="favourites" />
              </div>
              <div className="mt-4">
                <h3 className="text-base font-semibold">
                  Limitsiz kofe üçün abunə ol
                </h3>
                <p className="mt-2 text-sm leading-6">
                  Coffein tətbiqində abunəlik əldə edərək istənilən partnyor
                  şəbəkəsində ödənişsiz kofe əldə edə bilərsiniz.{" "}
                </p>
              </div>
            </div>
            <div className="bg-[#F7F7F7] p-6 rounded-2xl w-full">
              <div className="w-12 h-12">
                <img src={Reboot} alt="reboot" />
              </div>
              <div className="mt-4">
                <h3 className="text-base font-semibold">
                  Balansdan ödə, 10% keşbək qazan
                </h3>
                <p className="mt-2 text-sm leading-6">
                  Ödənişi coffeein hesabınızdakı balansınızla edərək 10% və
                  limitsiz keşbək qazana bilərsiniz.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerModal;
