import React from "react";

const PartnersCard = ({ Image, Price, ParnetrName, Address, Rate, Latitude, Longtitude, onClick }) => {
  
  
  return (
    <div className="max-h-[308px] w-full cursor-pointer" onClick={onClick}>
      <div className="w-full   relative">
        <img className="w-full max-h-[242px] rounded-2xl object-cover " src={Image} alt="coffee" />
        <div className="bg-white py-1 px-3  rounded-[22px] flex  items-center gap-[6px] absolute top-3 left-3">
          <span className="text-lg text-black">{Price}%</span>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.88981 0.0346585C6.51679 0.0404341 6.13612 0.074657 5.75157 0.141982C2.94255 0.634902 0.674296 2.87553 0.155439 5.68112C-0.6613 10.0983 2.72593 13.9667 6.99992 13.9667C10.5778 13.9667 13.5335 11.2561 13.923 7.7809C13.9876 7.20666 13.5381 6.69999 12.9583 6.69999C12.899 6.69999 12.8406 6.7054 12.7837 6.71581C12.6989 6.73132 12.6173 6.75795 12.5409 6.79432C12.2542 6.9308 12.0407 7.20462 12.0038 7.54441C11.7314 10.0639 9.5902 12.0333 6.99992 12.0333C3.89115 12.0333 1.43167 9.20087 2.06768 5.97865C2.44967 4.04294 4.00068 2.47655 5.93297 2.07765C6.56675 1.94684 7.18453 1.93653 7.76876 2.02771C8.74414 2.17993 9.62599 2.61499 10.3326 3.24433L9.72267 3.85427L9.59768 3.97926C9.43733 4.13962 9.36702 4.33736 9.36941 4.53055C9.37427 4.92475 9.68178 5.29999 10.1445 5.29999H10.4474H11.2692H12.9999C13.5336 5.29999 13.9666 4.86701 13.9666 4.33332V1.47785C13.9666 0.789319 13.1336 0.440929 12.6456 0.930019L11.7001 1.87557C10.437 0.712799 8.74682 0.00590501 6.88981 0.0346585Z"
              fill="#D9D9D9"
            />
            <path
              d="M6.88981 0.0346585C6.51679 0.0404341 6.13612 0.074657 5.75157 0.141982C2.94255 0.634902 0.674296 2.87553 0.155439 5.68112C-0.6613 10.0983 2.72593 13.9667 6.99992 13.9667C10.5778 13.9667 13.5335 11.2561 13.923 7.7809C13.9876 7.20666 13.5381 6.69999 12.9583 6.69999C12.899 6.69999 12.8406 6.7054 12.7837 6.71581C12.6989 6.73132 12.6173 6.75795 12.5409 6.79432C12.2542 6.9308 12.0407 7.20462 12.0038 7.54441C11.7314 10.0639 9.5902 12.0333 6.99992 12.0333C3.89115 12.0333 1.43167 9.20087 2.06768 5.97865C2.44967 4.04294 4.00068 2.47655 5.93297 2.07765C6.56675 1.94684 7.18453 1.93653 7.76876 2.02771C8.74414 2.17993 9.62599 2.61499 10.3326 3.24433L9.72267 3.85427L9.59768 3.97926C9.43733 4.13962 9.36702 4.33736 9.36941 4.53055C9.37427 4.92475 9.68178 5.29999 10.1445 5.29999H10.4474H11.2692H12.9999C13.5336 5.29999 13.9666 4.86701 13.9666 4.33332V1.47785C13.9666 0.789319 13.1336 0.440929 12.6456 0.930019L11.7001 1.87557C10.437 0.712799 8.74682 0.00590501 6.88981 0.0346585Z"
              fill="url(#paint0_linear_6224_5259)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6224_5259"
                x1="0.0332032"
                y1="7.00023"
                x2="13.0863"
                y2="10.3901"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#40CC72" />
                <stop offset="1" stopColor="#5B68F6" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="pt-4">
        <div className="flex justify-between">
          <h3 className="text-black text-lg font-semibold">{ParnetrName}</h3>
          <p className="text-black text-base font-medium flex items-center gap-1">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99945 1.66663L5.96179 5.82583L1.33325 6.49699L4.68622 9.77499L3.88472 14.3333L7.99945 12.1397L12.1151 14.3333L11.3195 9.77499L14.6666 6.49699L10.0637 5.82583L7.99945 1.66663Z"
                  fill="black"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span>{Rate}</span>
          </p>
        </div>
        <a rel="noreferrer" target="_blank" href={`https://www.google.com/maps?q=${Latitude},${Longtitude}`} className="text-[#888888] mt-[2px]">{Address?.slice(0,40) + "..."}</a>
      </div>
    </div>
  );
};

export default PartnersCard;
