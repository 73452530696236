import axios from "axios";

const API_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : "https://BACKEND_URL/";

const Http = axios.create({
  baseURL: API_URL + "user-ws/v1",
});

export const PostMobile = ({ data }) => {
  return Http.post("/user/lead", data);
};
export const Postdelete = ({ data }) => {
  return Http.post("/user/deleterequest", data);
};

export const getListPartner = async ({ search }) => {
  const res = await Http.get(`/site/partner-list?search=${search}`);
  return res.data;
};

export const getPartner = async (id) => {
  const res = await Http.get(`/site/partners/${id}`);
  return res.data;
};
