import FirstAward from "../assets/a1f775994352a28da302a023bbe66147.png";
import SecondAward from "../assets/f5dfa207b20d6c21fe600720fd3a7401.jpeg";
import ThirdAward from "../assets/ae8b8c466cb0c7d4eab76ba05a76f803.png";
import AwardCard from "../components/AwardCard";
import { useTranslation } from "react-i18next";


const Awards = () => {
  const {t} = useTranslation()
  const awardsData = t('awardsData', { returnObjects: true });
  

  return (
    <section className="mt-8 md:mt-16 pb-10 md:pb-20 p-4">
      <h2 className="text-lg md:text-[40px] text-center font-semibold">{t('awards')}</h2>
      <div className="mt-10 flex flex-col gap-6 justify-center items-center">
        {awardsData[0].map((el) => {
          return <AwardCard {...el} />;
        })}
      </div>
    </section>
  );
};

export default Awards;
