import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [currentLanguage, setCurrenLanguage] = useState("az");
  const navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();
  const handleChangeLanguage = (e) => {
    const newLanguage = e.target.value;
    setCurrenLanguage(newLanguage);
    changeLanguage(newLanguage);
  };
  return (
    <header className="pt-3 xl:pt-0">
      <div className="flex justify-center items-center max-w-[1340px] mx-auto p-3 my-0 md:my-5">
        <div className="w-full flex justify-between  items-center">
          <div className="flex items-center gap-[370px]">
            <svg
              onClick={() => navigate("/")}
              className="h-[auto] w-[120px] md:w-[182px]  overflow-visible cursor-pointer"
              style={{}}
              width="182"
              height="48"
              viewBox="0 0 182 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5141 31.4699C10.4217 31.4699 8.66265 30.755 7.23293 29.3293C5.85141 27.8996 5.13655 26.1406 5.13655 24C5.13655 21.8594 5.85141 20.0964 7.23293 18.6707C8.66265 17.241 10.4217 16.5301 12.5141 16.5301C14.0562 16.5301 15.4297 16.9317 16.5382 17.6626C16.992 15.992 17.6265 14.3976 18.4177 12.8956C16.6827 11.992 14.6787 11.49 12.5623 11.49C8.99197 11.49 5.99197 12.6787 3.57028 15.1044C1.18876 17.4819 0 20.4297 0 24C0 27.5221 1.18876 30.4699 3.57028 32.8956C5.99599 35.3213 8.99197 36.5141 12.5623 36.5141C14.6827 36.5141 16.6787 36.0121 18.4137 35.1044C17.6225 33.5984 16.988 32.004 16.5341 30.3374C15.4056 31.0602 14.0201 31.4699 12.5141 31.4699Z"
                fill="#40C872"
              />
              <path
                d="M81.6586 6.91965C77.8996 6.58632 76.0442 8.1566 76.0442 11.6305V12.1084H81.6586V17.0562H76.0442V35.8996H70.9076V17.0562H67.2932V12.1084H70.9076V11.6305C70.9076 5.11242 74.6185 1.54214 81.6627 1.97186V6.91965H81.6586Z"
                fill="#40C872"
              />
              <path
                d="M97.739 6.91965C93.9799 6.58632 92.1245 8.1566 92.1245 11.6305V12.1084H97.739V17.0562H92.1245V35.8996H86.9839V17.0562H83.3695V12.1084H86.9839V11.6305C86.9839 5.11242 90.6948 1.54214 97.739 1.97186V6.91965Z"
                fill="#40C872"
              />
              <path
                d="M102.92 26.1888C103.683 29.8032 106.582 31.8032 110.534 31.8032C113.245 31.8032 115.293 30.8032 116.671 28.8514L120.904 31.3253C118.57 34.7992 115.1 36.51 110.482 36.51C106.627 36.51 103.486 35.3213 101.108 32.988C98.7309 30.6104 97.5382 27.6104 97.5382 23.996C97.5382 20.4257 98.7269 17.4779 101.06 15.0964C103.394 12.6707 106.438 11.4819 110.1 11.4819C113.574 11.4819 116.43 12.7189 118.667 15.1446C120.904 17.5703 122.044 20.5703 122.044 24.0442C122.044 24.5663 121.996 25.2811 121.855 26.1847H102.92V26.1888ZM116.863 22.004C116.149 18.1486 113.438 16.1526 110.06 16.1526C106.253 16.1526 103.59 18.3896 102.876 22.004H116.863Z"
                fill="#40C872"
              />
              <path
                d="M129.707 26.1888C130.47 29.8032 133.369 31.8032 137.321 31.8032C140.032 31.8032 142.08 30.8032 143.458 28.8514L147.691 31.3253C145.357 34.7992 141.888 36.51 137.269 36.51C133.414 36.51 130.273 35.3213 127.896 32.988C125.518 30.6104 124.325 27.6104 124.325 23.996C124.325 20.4257 125.514 17.4779 127.847 15.0964C130.181 12.6707 133.225 11.4819 136.888 11.4819C140.361 11.4819 143.217 12.7189 145.454 15.1446C147.691 17.5703 148.831 20.5703 148.831 24.0442C148.831 24.5663 148.783 25.2811 148.643 26.1847H129.707V26.1888ZM143.651 22.004C142.936 18.1486 140.225 16.1526 136.847 16.1526C133.04 16.1526 130.378 18.3896 129.663 22.004H143.651Z"
                fill="#40C872"
              />
              <path
                d="M151.835 7.58632C150.55 6.25299 150.55 4.257 151.835 2.97186C153.121 1.63853 155.213 1.63853 156.498 2.97186C157.783 4.257 157.783 6.25299 156.45 7.58632C155.165 8.87146 153.165 8.87146 151.835 7.58632ZM151.598 35.8956V12.1044H156.739V35.8956H151.598Z"
                fill="#40C872"
              />
              <path
                d="M181.578 21.2891V35.8956H176.438V21.8112C176.438 18.2409 174.394 16.2932 171.108 16.2932C167.494 16.2932 165.064 18.5301 165.064 23.1446V35.8956H159.924V12.1044H165.064V15.1486C166.635 12.7229 169.06 11.4859 172.438 11.4859C177.912 11.4859 181.578 15.1968 181.578 21.2891Z"
                fill="#40C872"
              />
              <path
                d="M42.3454 48C29.1125 48 18.3454 37.2329 18.3454 24C18.3454 10.7671 29.1125 0 42.3454 0C55.5783 0 66.3454 10.7671 66.3454 24C66.3454 37.2329 55.5783 48 42.3454 48ZM42.3454 5.64659C32.2249 5.64659 23.992 13.8795 23.992 24C23.992 34.1205 32.2249 42.3534 42.3454 42.3534C52.4659 42.3534 60.7028 34.1205 60.7028 24C60.7028 13.8795 52.4659 5.64659 42.3454 5.64659Z"
                fill="#40C872"
              />
            </svg>
            <ul className="hidden md:flex gap-10 text-black text-sm md:text-base font-medium">
              <li>
                <Link to="/"> {t("coffeeshops")}</Link>{" "}
              </li>
              <li>
                <Link to="/awards"> {t("awards")}</Link>{" "}
              </li>
            </ul>
          </div>
          <select
            className="block md:hidden pl-2 w-[55px]  border border-solid rounded-lg focus-visible:outline-none focus-visible:border-none"
            onChange={handleChangeLanguage}
            value={currentLanguage}
          >
            <option value={"az"}>AZ</option>
            <option value={"en"}>EN</option>
          </select>
          <div className="hidden md:flex  gap-4">
            <select
              className=" pl-2 w-[55px]  border border-solid rounded-lg focus-visible:outline-none focus-visible:border-none"
              onChange={handleChangeLanguage}
              value={currentLanguage}
            >
              <option value={"az"}>AZ</option>
              <option value={"en"}>EN</option>
            </select>
            {/* <button className="hidden bg-[#40C872] px-4 py-2 text-sm font-medium rounded-[10px] font-['SanFrancisco',_sans-serif] text-white">
              Tərəfdaş ol
            </button> */}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center md:hidden">
        <ul className="flex gap-10 text-black text-sm md:text-base font-medium">
          <li>
            <Link to="/"> {t("coffeeshops")}</Link>{" "}
          </li>
          <li>
            <Link to="/awards"> {t("awards")}</Link>{" "}
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
